import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div id="hero-wrapper">
        <div className="image-content">
          <img src={"/image1.png"} className="main-image" />
        </div>
        <div className="text-content">
          <div id="text-wrapper">
            <h1>Upptäck Koh Lanta</h1>
            <h4>
              Ett community fokuserat på ön Koh Lanta i Thailand. Stränder,
              resturanger och aktiviteter
            </h4>
            <a href="https://apps.apple.com/se/app/koh-lanta-appen/id6475043840">
              <img src={"/app_store.svg"} style={{ minWidth: 250 }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
